@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins/_breakpoints";

.contact-hero {
  position: relative;
  min-height: 100vh;
  background: linear-gradient(rgba(0, 0, 0, 0.56), rgba(0, 0, 0, 0.56)), url(../images/contact-hero.jpg);
  background-size: cover;
  background-position: center;
  padding-left: 0px !important;
  padding-right: 0px !important;
  padding-top: 80px;
  @include media-breakpoint-up(md) {
    padding-top: calc(100vh / 5);
    padding-left: 15px;
    padding-right: 15px;
  }
}

.contact-hero-inset {
  background-color: rgba(0, 0, 0, 0.52);
  margin-bottom: 0px;
  padding-left: 15px;
  padding-right: 15px;
  @include media-breakpoint-up(md) {
    margin-bottom: 35px;
    padding-left: 100px;
    padding-right: 100px;
  }
}

.contact-hero-inset-title {
  font-family: "Poppins", sans-serif;
  font-size: 65px;
  color: rgb(255, 255, 255);
  text-transform: uppercase;
  font-weight: 600;
  margin-top: 43px;
}

.contact-hero-inset-text {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  color: rgb(255, 255, 255);
  font-weight: 400;
}

.contact-form-input {
  color: #fff !important;
  margin-bottom: 10px;
  height: 61px !important;
}

.contact-form-textarea {
  color: #fff !important;
  margin-bottom: 10px;
  height: 100% !important;
  min-height: 200px;
}

.contact-form-submit {
  width: 100%;
  margin-top: 15px;
  margin-bottom: 43px;
  height: 61px !important;
}