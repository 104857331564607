@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins/_breakpoints";

.about-hero {
    position: relative;
    width: 100%;
    height: 421px;
    background: 
    linear-gradient(
      rgba(0, 0, 0, 0.4),
      rgba(0, 0, 0, 0.4)
    ),
    url(../images/about-callout.jpg);
    background-size: cover;
    background-position: center; 
  }

  .about-hero-title {
    font-family: "Poppins", sans-serif;
    font-size: 70px;
    color: rgb(254, 254, 254);
    text-transform: uppercase;
    font-weight: 700;
  }

  .about-text {
    font-family: "Poppins", sans-serif;
    font-size: 17px;
    font-weight: 500;
    line-height: 27px;
    margin-top: 52px;
    margin-bottom: 35px;
  }

  .about-separator {
    border-top: 1px solid rgba(0, 0, 0, 0.2);
  }

  .about-team-members {
    padding-top: 35px;
    padding-bottom: 35px;
  }

  .about-team-members-name {
    font-family: "Poppins", sans-serif;
    font-size: 27px;
    color: rgb(27, 27, 27);
    font-weight: 600;
  }

  .about-team-members-title {
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    margin-bottom: 28px;
  }

  .about-team-members-desc {
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    line-height: 24px;
    color: rgb(58, 58, 58);
    font-weight: 400;
  }