@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins/_breakpoints";

.home-hero {
    position: relative;
    height: 100vh;
    background-image: 
      linear-gradient(
        rgba(0, 0, 0, 0.7),
        rgba(0, 0, 0, 0.7)
      ),
      url(../images/home-hero.png);

    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

.home-title-1 {
    font-size: 60px;
    font-style: normal;
    font-family: "Poppins", sans-serif; 
    color: rgb(255, 255, 255);
    line-height: 1.25em;

    @include media-breakpoint-down(sm) { 
      font-size: 45px;
    }
}

.home-title-2 {
    font-size: 70px;
    font-style: normal;
    font-family: "Playfair Display", sans-serif; 
    color: rgb(0, 183, 207);
    line-height: 1.25em;

    @include media-breakpoint-down(sm) { 
      font-size: 55px;
    }
}

.home-title-3 {
  font-size: 35px;
  font-family: "Poppins", sans-serif; 
  font-weight: 600;
  letter-spacing: -1px;
  line-height: 50px;
  margin-bottom: 16px;
}

.home-company-desc {
  font-family: "Roboto", sans-serif; 
  font-size: 21px;
  line-height: 26px;
}

.home-logo {
  margin-top: 45px;
  margin-bottom: 35px;
}

.home-who-we-are-panel {
  padding-bottom: 45px;
}

.home-what-we-do-panel {
  padding-top: 35px;
  padding-bottom: 37px;
}

.home-what-we-do-subtitle {
  font-size: 15px;
  color: rgb(141, 141, 141);
  letter-spacing: 4px;
  line-height: 40px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  text-transform: uppercase;
}

.home-services-panel {
  padding-top: 40px;
  padding-bottom: 45px;
}

.title-separator {
  width: 61px;
  margin: 0 auto 10px auto;
  border-top: 4px solid #00b7cf;
}

.white-row {
  background-color: #fff;
}

.grey-row {
  background-color: #f1f1f1;
}

.home-service {
  margin-bottom: 4em;
  margin-right: 1em;
}

.home-service-icon {
  color: rgb(182, 182, 182);
  font-size: 5em;
  margin-right: 10px;
}

.home-service-header {
  margin-bottom: 0.8em;
  display: flex;
  align-items: center;
}

.home-service-title {
  display: inline-block;
  font-family: "Poppins", sans-serif;
  color: rgb(51, 51, 51);
  font-weight: 700;
}

.home-service-description {
  font-size: 0.9em;
  font-family: "Poppins", sans-serif;
  color: rgb(51, 51, 51);
  opacity: 0.7;
  line-height: 2em;
}